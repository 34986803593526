import type { NextPage } from 'next';
import { useMemo } from 'react';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { Layout as AppLayout } from 'app/Layout';
import { AmountFilter } from 'features/AmountFilter';
import { BrandingHeaders } from 'widgets/BrandingHeaders';
import { FullDealEntryPoint } from 'widgets/FullDealEntryPoint';
import { useIsShowFullDealEntrypoint } from 'widgets/FullDealEntryPoint/lib';
import { getRenderCard } from 'widgets/LightweightCard/lib';
import { MobileSubHeader } from 'widgets/MobileSubHeader';
import { SeoComponents } from 'widgets/SeoComponents';

import styles from './PodPtsPage.module.scss';

const pageConfig: IPageContextConfig = {
  listKey: ListKeys.ZAIMY_POD_PTS,
  source: 'search',
  sortPanelVisible: true,
  seoLinksVisible: true,
  extraFiltersVisible: true,
  cardTermIsVisible: true,
  withBanners: true,
  withInvitationBanner: true,
  withEmailBurner: true,
  withSelectionBanner: true,
  withCardProductLink: true,
};

export const PodPtsPage: NextPage = () => {
  const { filter, seo, pathname, hasUtmLabel, isMobile, asPath } = useListPage(ListKeys.ZAIMY_POD_PTS);
  const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);

  const [pathWithoutQuery] = asPath.split('?');

  useLoadRestOffers(ListKeys.ZAIMY_POD_PTS);
  useBestProducts();

  const isShowFullDealEntrypoint = useIsShowFullDealEntrypoint();

  return (
    <AppLayout>
      <PageContext.Provider value={config}>
        <BrandingHeaders
          containerClassName={styles.page_header}
          afterHeaders={
            isShowFullDealEntrypoint ? (
              <FullDealEntryPoint heading={seo?.heading} />
            ) : (
              <AmountFilter filters={filter} listKey={ListKeys.ZAIMY_POD_PTS} />
            )
          }
        />

        {isMobile && <MobileSubHeader />}

        <ProductList
          className={styles.product_list}
          gridLayout
          filter={filter}
          pathname={pathname}
          renderCard={(props) => getRenderCard(props, pathWithoutQuery, seo, isMobile, hasUtmLabel, true)}
        />

        {seo && (
          <SeoComponents
            seo={seo}
            breadcrumbs={seo.breadcrumbs}
            prefooter={seo.prefooter}
            showAnchorLinks={false}
            showSubheader={false}
          />
        )}
        <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
      </PageContext.Provider>
    </AppLayout>
  );
};

PodPtsPage.getInitialProps = async (ctx: Application.ReduxNextPageContext) => {
  await getPageListData(ctx, {
    key: ListKeys.ZAIMY_POD_PTS,
    defaultFiltersMode: MODES.ZAIMY_POD_PTS,
  });
};
